
.loader{
  width: 100%;
  height: 100vh;
  z-index: 333;
}

.placeLoader{
  color: black;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

