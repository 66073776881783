.container8 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 20px; /* Adjust as needed for spacing */
    position: sticky;
    
  }
  
  .textdiv1 {
    background-color: #0F4D52; /* Background color for each card */
    color: white;
    padding: 20px; /* Adjust as needed for spacing and padding inside the card */
    margin-bottom: 20px; /* Adjust as needed for spacing between cards */
    border-radius: 10px; /* Adjust as needed for card border radius */
    position: sticky;
    top: 14rem;
  }
  
  .textdiv1 img {
    width: 100%; /* Make the image fill the width of its container */
    height: auto; /* Maintain the aspect ratio of the image */
    border-radius: 10px; /* Adjust as needed for image border radius */
    height: 35vh;
    /* float: right; */
  }
  
  /* Add additional styles as needed */
  